<template>
  
  <div > 
  <h1> Thank You for Subscribing! </h1>
  <p>If you have any questions or concerns regarding this, do not hesitate to contact us at adminuser@vidhyaan.com</p>
  <button
                    type="button"
                    @click.prevent="userLogin"
                    class="btn btncl clsmdl"
                    id="clbtn"
                  >
                       <span>Login</span>
                  </button>
  </div>

</template>
<script>

import secureUI from "../utils/secureUI";
import ViService from "@/services/ViService";

export default {
  name: "vi-payment-successfully",
  
  data() {
    return {
      
    };
  },
  created() {
	  this.paymentCompleted();
  },
  methods: {
   
    async paymentCompleted() {
       
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        
         const response = await ViService.viXGet(`/zohoApiCall/paymentCompleted?hostedPageId=${this.$route.query.hostedpage_id}`,userData.token );
         
         if (response.isSuccess) {
           if(userData){
            setTimeout(() => {
                this.$store.dispatch("setIndividualSchoolInfo",{});
                localStorage.removeItem('user');
                this.$router.push('/login');
              }, 10000);
           }else{
            setTimeout(() => {
                this.$store.dispatch("setIndividualSchoolInfo",{});
                localStorage.removeItem('user');
                this.$router.push('/login');
              }, 10000);
           }

           

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
        }
      }
    
       
    }},
    userLogin(){
     this.$router.push('/login');
    }

};
</script>

<style lang="scss" scoped></style>